/* tab view */
@media (max-width: 1200px) {
  .selected-date-style {
    margin-top: 2rem;
  }
  .calendars {
    width: 500px !important;
  }
  .calendars-grid {
    /* gap: 40px !important; */
    row-gap: 40px !important;
    padding: 10px !important;
    margin-top: 20px !important;
  }
}

/* mobile view */
@media (max-width: 768px) {
  .selected-date-style {
    margin-top: 2rem;
  }
  .calendars {
    width: 450px !important;
  }
  .calendars-grid {
    /* gap: 40px !important; */
    row-gap: 40px !important;
    padding: 10px !important;
    margin-top: 20px !important;
  }
  .calendars-day.select {
    background-color: #c6a34f;
    color: #fff;
    border-radius: 50%;
    /* margin: 0 7px; */
  }
  .month-year {
    font-size: 11px !important;
  }
  .calendars-day {
    padding: 0px !important;
  }
}
/* mobile view */
@media (max-width: 450px) {
  .selected-date-style {
    margin-top: 2rem;
  }
  .calendars {
    width: 400px !important;
  }
  .calendars-grid {
    /* gap: 30px !important; */
    row-gap: 20px !important;
    padding: 10px !important;
    margin-top: 20px !important;
  }
}

.ant-card {
  background: transparent !important;
}

/* Calendar container */
.calendars {
  font-family: Montserrat, sans-serif;
  width: 700px;
  margin: 0 auto;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  border: 0.5px solid #c6a34f;
  /* box-shadow: 0px 1.33947px 13.3947px rgba(0, 0, 0, 0.1); */
  padding: 10px;
}

.calendars-day-name {
  font-size: 12px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  padding: 15px;
  border-radius: 5px;
  background-color: #c6a34f;
  border: 0.5px solid #ededed;
  box-shadow: 0px 1.33947px 13.3947px rgba(0, 0, 0, 0.1);
}

/* Calendar header */
.calendars-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
}

/* Calendar header h2 */
.calendars-header h2 {
  margin: 0;
}

/* Calendar navigation buttons */
.calendars-header button {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  outline: none;
}
.calendars-header.day-name {
  color: white;
}

/* Calendar grid */
.calendars-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* gap: 60px; */
  row-gap: 40px;
  padding: 10px;
  margin-top: 20px;
}

/* Calendar day cell */
.calendars-day {
  /* display: flex; */
  padding: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
}

/* Hover effect */
.calendars-day:hover {
  /* background-color: #ddd; */
  /* border-radius: 50%; */
}

/* Style for middle date in the range */
.calendars-day.middle {
  background-color: #c6a34f;
  color: #fff;
}

/* Style for selected date without an end date */
.null-end-date {
  background-color: #c6a34f;
  color: #fff;
}

/* Selected date cell */
.calendars-day.select {
  background-color: #d29f2ace;
  color: #fff;
  border-radius: 50%;
  /* margin: 0 7px; */
}

/* Range start date cell */
.calendars .calendars-day.range-start {
  border-radius: 50% 0 0 50%;
  background-color: #c6a34f; /* Your desired background color for the first date */
  color: #fff; /* Your desired text color for the first date */
}

/* Range end date cell */
.calendars .calendars-day.range-end {
  border-radius: 0 50% 50% 0;
  background-color: #c6a34f; /* Your desired background color for the last date */
  color: #fff; /* Your desired text color for the last date */
}

/* Dates within the range */
.calendars .calendars-day.within-range {
  background-color: #d29f2ace; /* Your desired background color for middle dates */
  color: #fff; /* Your desired text color for middle dates */
  /* border-radius: 50%; */
}

.circle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33%; /* Set the width for each container to divide the row into 3 equal parts */
  margin-right: 10px; /* Adjust the margin as needed */
}
.circle {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  width: 100px;
}

.green {
  color: #00c92c;
}

.green1 {
  background: #00c92c;
  width: 10px;
  height: 10px;
  border-radius: 3px;
  margin-right: 5px;
}

.blue {
  color: blue;
  cursor: not-allowed;
  pointer-events: none;
}
.blue1 {
  background: blue;
  width: 10px;
  height: 10px;
  border-radius: 3px;
  margin-right: 5px;
}

.red {
  color: red;
  cursor: not-allowed;
  pointer-events: none;
}

.red1 {
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 3px;
  margin-right: 5px;
}

.yellow {
  color: rgb(205, 205, 100);
  cursor: not-allowed;
  pointer-events: none;
}
.yellow1 {
  background: rgb(205, 205, 100);
  width: 10px;
  height: 10px;
  border-radius: 3px;
  margin-right: 5px;
}

.select {
  background: #c6a34f;
  color: white;
  border-radius: 50%;
}

.select1 {
  background: #c6a34f;
  width: 10px;
  height: 10px;
  border-radius: 3px;
  margin-right: 5px;
  cursor: not-allowed;
  pointer-events: none;
}

.disabled {
  background: #ddd;
  width: 10px;
  height: 10px;
  border-radius: 3px;
}
.disabled1 {
  color: #909090;
  cursor: not-allowed;
  pointer-events: none;
}

span {
  margin-right: 10px;
}

.selected-date-style {
  background: white;
  padding: 10px;
  border-radius: 10px;
  border: 0.5px solid #c6a34f;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #c6a34f;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  width: 12px; /* Adjust the size as needed */
  height: 12px; /* Adjust the size as needed */
  background-color: #c6a34f;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* range start date cell */

.single-range{
  border-radius: 50%;
  background-color: #c6a34f !important; /* Your desired background color for the first date */
  color: #fff; /* Your desired text color for the first date */
  padding-left: 2px;
  margin-left: 1px;
  
}

.range-start {
  border-radius: 50% 0 0 50%;
  background-color: #c6a34f !important; /* Your desired background color for the first date */
  color: #fff; /* Your desired text color for the first date */
  padding-left: 2px;
  margin-left: 1px;
}
.range-end {
  border-radius: 0 50% 50% 0;
  background-color: #c6a34f !important; /* Your desired background color for the last date */
  color: #fff; /* Your desired text color for the last date */
  margin-right: 1px;
}


.job-range {
  background-color: #c6a34f !important; /* Your desired background color for the first date */
  color: #fff; /* Your desired text color for the first date */
  padding-left: 2px;
}
.job-start{
  border-radius: 50% 0 0 50%;
  background-color: #c6a34f !important; /* Your desired background color for the first date */
  color: #fff; /* Your desired text color for the first date */
  padding-left: 2px;
  margin-left: 1px;
}
.job-end {
  border-radius: 0 50% 50% 0;
  background-color: #c6a34f !important; /* Your desired background color for the last date */
  color: #fff; /* Your desired text color for the last date */
  margin-right: 1px;
}
.jobStartAndEnd{
  border-radius: 50%;
  background-color: #c6a34f !important; /* Your desired background color for the first date */
  color: #fff; /* Your desired text color for the first date */
  padding-left: 2px;
  margin-left: 1px;
  margin-right: 1px;
}
.calendars-day.past-date {
  color: #909090;
  cursor: not-allowed;
  pointer-events: none;
}