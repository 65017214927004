@media print {
  .print-content {
    text-align: center !important; /* Center-align most of the content */
    padding: 20px !important;
  }

  .print-content .to {
    text-align: left; /* Align the 'to' section to the left */
  }
}
