@import url("./components/login/_login.css");
@import url("./components/login/_media.css");
@import url("./components/register/_register.css");
@import url("./components/register/_media.css");
@import url("./components/home/_style.css");
@import url("./components/home/_media.css");
@import url("./components/dashboard/_media.css");
@import url("./components/dashboard/_index.css");
@import url("./components/client/myemployee/_style.css");
@import url("./components/client/myemployee/_media.css");
@import url("./components/client/employeeViewDetails/_style.css");
@import url("./components/client/employeeViewDetails/_media.css");
@import url("./components/aboutUs/_style.css");
@import url("./components/shortList/_shortList.css");
@import url("./components/paymentInvoice/_paymentInvoice.css");
@import url("./components/paymentInvoice/_paymentInvoiceMedia.css");
@import url("./components/calender/_calenderMedia.css");

.ant-table-thead .ant-table-cell {
  background-color: #c6a34f !important;
}
.not-allowed-cursor {
  cursor: not-allowed;
}