@import url('https://fonts.googleapis.com/css2?family=josefin+Sans:wght@400;500;600;700&display=swap');

.CardSection {

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Josefin Sans', sans-serif;
    }

    .container {
        padding: 10px;
        width: 100%;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card {
        width: 500px;
        height: 300px;
        color: #fff;
        cursor: pointer;
        perspective: 1000px;
    }

    .card-inner {
        width: 100%;
        height: 100%;
        position: relative;
        transition: transform 1s;
        transform-style: preserve-3d;
    }

    .front,
    .back {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(45deg, #0045c7, #ff2c7d);
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px 30px;
        border-radius: 15px;
        overflow: hidden;
        z-index: 1;
        backface-visibility: hidden;
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .map-img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.3;
        z-index: -1;
    }

    .card-no {
        font-size: 35px;
        margin-top: 60px;
    }

    .card-holder {
        font-size: 12px;
        margin-top: 40px;
    }

    .name {
        font-size: 22px;
        margin-top: 20px;
    }

    .bar {
        background: #222;
        margin-left: -30px;
        margin-right: -30px;
        height: 60px;
        margin-top: 10px;
    }

    .card-cvv {
        margin-top: 20px;
    }

    .card-cvv div {
        flex: 1;
    }

    .card-cvv img {
        width: 100%;
        display: block;
        line-height: 0;
    }

    .card-cvv p {
        background: #fff;
        color: #000;
        font-size: 22px;
        padding: 10px 20px;
    }

    .card-text {
        margin-top: 30px;
        font-size: 14px;
    }

    .signature {
        margin-top: 30px;
    }

    .back {
        transform: rotateY(180deg);
    }

    .card:hover .card-inner {
        transform: rotateY(-180deg);
    }
}