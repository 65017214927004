/* font-family: "Montserrat", sans-serif;
font-family: "Poppins", sans-serif; */
body {
  background-color: #f5f5f5;
}

.InnnerDashboardSearch {
  padding: 25px 0px;
  background-color: #f5f5f5;
}

.innerdashboardBackButton {
  margin-right: 15px;
  width: 49px;
  height: 48px;
  border-radius: 14px;
  border: 1px solid #a6a6a6;
  background-color: #ffffff;
}

.commonBackButton {
  margin-right: 12px;
  width: 40px;
  height: 40px;
  border-radius: 14px;
  border: 1px solid #a6a6a6;
  background-color: #ffffff;
}

.innerDashSearchItemsSpan {
  /* margin-top: 5px !important; */
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #111111;
}

.innerDashRightSideSearchBar {
  margin-top: 7px;
  font-size: 13px;
  color: #7b7b7b;
  padding-left: 50px;
  border-radius: 72px;
  background: white;
  box-shadow: 6px 6px 12px #aaaaaa, -6px -6px 12px #ffffff;
}

.InnerDashSearchCion {
  position: relative;
}

.InnerDashSearchCion img {
  position: absolute;
  top: -4px;
  left: 17px;
}

/* Table Start */
.InnnerDashboardTable {
  margin-bottom: 50px;
}

.InnerTableEmployeeName {
  font-size: 15.48px;
  font-weight: 500;
  color: #5c5c5c;
}

.InnerTableEmployeeDesignation {
  margin-bottom: 0px;
  font-size: 14.48px;
  font-weight: 500;
  color: #5c5c5c;
}

.InnerDashTableSelectDselectWrap .btn {
  border-radius: 0;
}

.InnerDashTableSelectDselectWrap .btn:first-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.InnerDashTableSelectDselectWrap .btn:last-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  margin-left: -1px;
}

.InnerDashTableSelectDselectWrap {
  background-color: #ffffff;
  border: 0.8px solid #dadada;
  border-radius: 6.2px;
}

.InnerDashLineImageBtn {
  margin-right: 3px !important;
}

.InnerTableHeadingGlobalStyle {
  /* padding-top: 28px !important;
    padding-bottom: 21px !important;
    padding-left: 10px !important;
    padding-right: 10px !important; */
  /* font-size: 17.36px !important; */
  font-weight: 500 !important;
  color: white;
  background-color: #c6a34f !important;
}

.InnerTableHeadingGlobalStyleFirstChild {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.InnerTableHeadingGlobalStylelastChild {
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.InnerTableDateShow {
  border: none !important;
}

.InnerTableDateShow {
  font-size: 16px;
  font-weight: 600;
  color: #313131;
}

.InnerTablebodyItemPaddingTopFix {
  font-size: 16px;
  font-weight: 600;
  color: #7b7b7b;
  padding-top: 17px !important;
}

.TableChatIcon {
  border: none;
  background-color: transparent;
}
