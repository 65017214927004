.places-container {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%);
    z-index: 10;
    width: 300px;
}

.map-container {
    width: 100%;
    height: 100vh;
}

.combobox-input {
    width: 100%;
    padding: 0.5rem;
}