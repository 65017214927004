.location-serach-box {
  width: 80%;
  max-width: 520px;
  position: absolute;
  top: 130px;
  left: 30px;
  z-index: 10;
  overflow: hidden;

  .childbox {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 6px rgb(0 0 0 / 16%);
    padding: 2px 5px;
    border-radius: 10px;
    overflow: hidden;

    .search-map-field {
      width: 450px;
      border: none;
      outline: 0;
      box-shadow: none;

      &:focus {
        border: none;
        outline: 0;
        box-shadow: none;
      }
    }

    .icon {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 10px;
    }
  }

  ul {
    // position: absolute;
    // top: 115%;
    // z-index: 1;
    background: #fff;
    box-shadow: 0 0 6px rgb(0 0 0 / 16%);
    border: none;
    border-radius: 10px;
    max-height: 150px;
    overflow-y: auto;
    padding-left: 1rem;
    width: 100%;

    li {
      list-style: none;
      cursor: pointer;
    }
  }
}