@media (min-width: 320px) and (max-width: 374.98px) {
    .innerDashSearchItemsSpan {
        font-size: 16px !important;
    }

    .InvoicePaymentTable {
        padding: 20px 0px;
    }

    .InvoiceAndPaymentTableHead th {
        padding: 10px;
    }

    .InvoiceAndPaymentTableBody td {
        font-size: 13px;
        padding: 10px;
    }
}

@media (min-width: 375px) and (max-width: 575.98px) {
    .innerDashSearchItemsSpan {
        font-size: 16px !important;
    }

    .InvoicePaymentTable {
        padding: 10px 0px;
    }

    .InvoiceAndPaymentTableHead th {
        padding: 5px;
        font-size: 13px;
    }

    .InvoicePayButton {
        font-size: 11px;
        padding: 5px 12px;
    }

    .InvoiceAndPaymentTableBody td {
        font-size: 13px;
        padding: 10px;
    }

    .InvoiceTableHeadingGreenColor {
        padding: 10px !important;
    }

    .weekDateWrapper span {
        font-size: 13px;
    }

    .InvoiceTableHeadingRedColor {
        padding: 6px !important;
    }
}

@media (min-width: 768px) and (max-width: 818.98px) {
    .innerDashSearchItemsSpan {
        font-size: 16px !important;
    }

    .InvoicePaymentTable {
        padding: 10px 0px;
    }

    .InvoiceAndPaymentTableHead th {
        padding: 15px;
        font-size: 13px;
    }

    .InvoicePayButton {
        font-size: 11px;
        padding: 5px 12px;
    }

    .InvoiceAndPaymentTableBody td {
        font-size: 13px;
        padding: 10px;
    }

    .InvoiceTableHeadingGreenColor {
        padding: 10px !important;
    }

    .weekDateWrapper span {
        font-size: 13px;
    }

    .InvoiceTableHeadingRedColor {
        padding: 6px !important;
    }
}

@media (min-width: 820px) and (max-width: 1023.98px) {
    .innerDashSearchItemsSpan {
        font-size: 16px !important;
    }

    .InvoicePaymentTable {
        padding: 10px 0px;
    }

    .InvoiceAndPaymentTableHead th {
        padding: 15px;
        font-size: 13px;
    }

    .InvoicePayButton {
        font-size: 11px;
        padding: 5px 12px;
    }

    .InvoiceAndPaymentTableBody td {
        font-size: 13px;
        padding: 10px;
    }

    .InvoiceTableHeadingGreenColor {
        padding: 10px !important;
    }

    .weekDateWrapper span {
        font-size: 13px;
    }

    .InvoiceTableHeadingRedColor {
        padding: 6px !important;
    }
}

@media (min-width: 1024px) and (max-width: 1198.98px) {}

@media (min-width: 1200px) and (max-width: 1339.98px) {}

@media (min-width: 1340px) and (max-width: 1399.98px) {}

@media (min-width: 1400px) and (max-width: 1535.98px) {}

@media (min-width: 1536px) and (max-width: 1598.98px) {}

@media (min-width: 1600px) and (max-width: 1798.98px) {}

@media (min-width: 1800px) and (max-width: 2098.98px) {}