.customCard {
  height: 130px;
  /* width: 200px; */
  margin: 5px;
  padding: 10px;
  /* border: 1px solid #a6a6a6; */
  border-radius: 10px;
  text-align: center;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background-color: #f6f1e5;
  position: relative;
}

.customCard img {
  height: 50px;
  width: 50px;
  margin: 0 auto;
}

.customCardLink {
  text-decoration: none;
  color: black; /* You can set the color as needed */
}

.employee-border {
  border: 1px solid #a6a6a6;
  border-radius: 10px;
}

.blur-container {
  position: relative;
  /* height: 180px; */
  width: 100%;
  color: white;
  padding: 20px;
  margin: 10px;
  overflow: hidden;
  border-radius: 10px;
}

.blur-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: inherit;
  filter: blur(5px);
}

.content {
  position: relative;
  z-index: 1;
}

.restaurant-name,
.restaurant-address {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}

.blur-container::before {
  background-color: rgba(0, 0, 0); /* Set the overlay color */
}

.resultCount {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  padding: 3px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
}
