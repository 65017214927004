.preloader16 {
    display: block;
    height: 50px;
    position: absolute;
    left: 50%;
    margin-top: 30px;
    transform: translateX(-50%);
}

.preloader16 span {
    width: 16px;
    height: 16px;
    background-color: #000;
    display: inline-block;
    background-color: #18bc9c;
    -webkit-animation: square2 2s infinite ease-in-out both;
    animation: square2 2s infinite ease-in-out both;
}

.preloader16 span:nth-child(1) {
    -webkit-animation: preloader_2_1 1.5s infinite ease-in-out;
    animation: preloader_2_1 1.5s infinite ease-in-out;
}

.preloader16 span:nth-child(2) {
    left: 20px;
    -webkit-animation: preloader_2_2 1.5s infinite ease-in-out;
    animation: preloader_2_2 1.5s infinite ease-in-out;
}

.preloader16 span:nth-child(3) {
    top: 0px;
    -webkit-animation: preloader_2_3 1.5s infinite ease-in-out;
    animation: preloader_2_3 1.5s infinite ease-in-out;
}

.preloader16 span:nth-child(4) {
    top: 0px;
    left: 20px;
    -webkit-animation: preloader_2_4 1.5s infinite ease-in-out;
    animation: preloader_2_4 1.5s infinite ease-in-out;
}

@-webkit-keyframes preloader_2_1 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
        transform: translateX(0px) translateY(0px) rotate(0deg);
        border-radius: 0px;
    }

    50% {
        -webkit-transform: translateX(-20px) translateY(-10px) rotate(-180deg);
        transform: translateX(-20px) translateY(-10px) rotate(-180deg);
        border-radius: 50%;
        background: #3498db;
    }

    80% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(-360deg);
        transform: translateX(0px) translateY(0px) rotate(-360deg);
        border-radius: 0px;
    }

    100% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(-360deg);
        transform: translateX(0px) translateY(0px) rotate(-360deg);
        border-radius: 0px;
    }
}

@keyframes preloader_2_1 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
        transform: translateX(0px) translateY(0px) rotate(0deg);
        border-radius: 0px;
    }

    50% {
        -webkit-transform: translateX(-20px) translateY(-10px) rotate(-180deg);
        transform: translateX(-20px) translateY(-10px) rotate(-180deg);
        border-radius: 50%;
        background: #3498db;
    }

    80% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(-360deg);
        transform: translateX(0px) translateY(0px) rotate(-360deg);
        border-radius: 0px;
    }

    100% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(-360deg);
        transform: translateX(0px) translateY(0px) rotate(-360deg);
        border-radius: 0px;
    }
}

@-webkit-keyframes preloader_2_2 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
        transform: translateX(0px) translateY(0px) rotate(0deg);
        border-radius: 0px;
    }

    50% {
        -webkit-transform: translateX(20px) translateY(-10px) rotate(180deg);
        transform: translateX(20px) translateY(-10px) rotate(180deg);
        border-radius: 50%;
        background: #f1c40f;
    }

    80% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(360deg);
        transform: translateX(0px) translateY(0px) rotate(360deg);
        border-radius: 0px;
    }

    100% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(360deg);
        transform: translateX(0px) translateY(0px) rotate(360deg);
        border-radius: 0px;
    }
}

@keyframes preloader_2_2 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
        transform: translateX(0px) translateY(0px) rotate(0deg);
        border-radius: 0px;
    }

    50% {
        -webkit-transform: translateX(20px) translateY(-10px) rotate(180deg);
        transform: translateX(20px) translateY(-10px) rotate(180deg);
        border-radius: 50%;
        background: #f1c40f;
    }

    80% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(360deg);
        transform: translateX(0px) translateY(0px) rotate(360deg);
        border-radius: 0px;
    }

    100% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(360deg);
        transform: translateX(0px) translateY(0px) rotate(360deg);
        border-radius: 0px;
    }
}

@-webkit-keyframes preloader_2_3 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
        transform: translateX(0px) translateY(0px) rotate(0deg);
        border-radius: 0px;
    }

    50% {
        -webkit-transform: translateX(-20px) translateY(10px) rotate(-180deg);
        transform: translateX(-20px) translateY(10px) rotate(-180deg);
        border-radius: 50%;
        background: #2ecc71;
    }

    80% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(-360deg);
        transform: translateX(0px) translateY(0px) rotate(-360deg);
        border-radius: 0px;
    }

    100% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(-360deg);
        transform: translateX(0px) translateY(0px) rotate(-360deg);
        border-radius: 0px;
    }
}

@keyframes preloader_2_3 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
        transform: translateX(0px) translateY(0px) rotate(0deg);
        border-radius: 0px;
    }

    50% {
        -webkit-transform: translateX(-20px) translateY(10px) rotate(-180deg);
        transform: translateX(-20px) translateY(10px) rotate(-180deg);
        border-radius: 50%;
        background: #2ecc71;
    }

    80% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(-360deg);
        transform: translateX(0px) translateY(0px) rotate(-360deg);
        border-radius: 0px;
    }

    100% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(-360deg);
        transform: translateX(0px) translateY(0px) rotate(-360deg);
        border-radius: 0px;
    }
}

@-webkit-keyframes preloader_2_4 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
        transform: translateX(0px) translateY(0px) rotate(0deg);
        border-radius: 0px;
    }

    50% {
        -webkit-transform: translateX(20px) translateY(10px) rotate(180deg);
        transform: translateX(20px) translateY(10px) rotate(180deg);
        border-radius: 50%;
        background: #e74c3c;
    }

    80% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(360deg);
        transform: translateX(0px) translateY(0px) rotate(360deg);
        border-radius: 0px;
    }

    100% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(360deg);
        transform: translateX(0px) translateY(0px) rotate(360deg);
        border-radius: 0px;
    }
}

@keyframes preloader_2_4 {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
        transform: translateX(0px) translateY(0px) rotate(0deg);
        border-radius: 0px;
    }

    50% {
        -webkit-transform: translateX(20px) translateY(10px) rotate(180deg);
        transform: translateX(20px) translateY(10px) rotate(180deg);
        border-radius: 50%;
        background: #e74c3c;
    }

    80% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(360deg);
        transform: translateX(0px) translateY(0px) rotate(360deg);
        border-radius: 0px;
    }

    100% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(360deg);
        transform: translateX(0px) translateY(0px) rotate(360deg);
        border-radius: 0px;
    }
}