/* font-family: 'Montserrat', sans-serif; */
/* font-family: "Montserrat", sans-serif;
font-family: "Poppins", sans-serif; */

.rating {
  display: inline-block;
  padding: 0px;
}

.rating input[type="radio"] {
  display: none;
}

.rating label {
  color: #ddd;
  font-size: 1.5em;
  padding: 0.2em;
  cursor: pointer;
  float: right;
}

.rating label:before {
  content: "\2605";
}

.rating input[type="radio"]:checked ~ label {
  color: #f9a825;
}

/* Header Start */

/* Dark Mode TOggle */
.toggle-img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.light-mode {
  display: none;
}

.dark-mode {
  display: inline-block;
}

.DashBoardNavform-check
  .form-check-input:checked
  ~ .form-check-label
  .dark-mode {
  display: none;
}

.DashBoardNavform-check
  .form-check-input:checked
  ~ .form-check-label
  .light-mode {
  display: inline-block;
}

.custom_formCheck_input:focus {
  border-color: #ffa800 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #ffaa0028 !important;
}

.custom_formCheck_input:checked {
  background-color: #ffa800;
  border-color: #ffffff;
}

.DashboarddarkModeToggle {
  padding-top: 9px;
}

/* Dark Mode TOggle */

/* Global Css */
body {
  font-family: "Montserrat", sans-serif;
  /* background-color: #f5f5f5; */
}

/* Global Css */

/* Header Start */

.Dashboardheader {
  background-color: #ffffff;
  z-index: 10;
}

.header_container {
  padding: 0px;
}

.Dashboard_navbar_custom ul li a {
  color: #111111 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 500;
}

.Dashboard_navbar_custom_hover {
  margin: 0px 10px;
}

.navButton {
  border: 1px solid #c6a34f;
  border-radius: 6px;
  background-color: #c6a34f1a;
}

.navButton a {
  text-decoration: none;
  color: #c6a34f;
}

.navButton a img {
  margin-right: 8px;
}

.navDropDownPlaceholder {
  color: #111111 !important;
  margin: 0px 8px;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 500;
}

.DashboardBookmarkNavSpan {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.DashboardNotificationNavSpan {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.DashboardBookmarkNavSpan:hover {
  color: #c6a34f;
  font-weight: 700;
}

.DashboardNotificationNavSpan:hover {
  color: #c6a34f;
  font-weight: 700;
}

/* Header End */
/* Dashboard 1 */
.dashboard1 {
  background-color: #f5f5f5;
  padding: 40px 0px;
}

.dashboard2 {
  background-color: #f5f5f5;
  padding: 0px 0px;
}

.pagination {
  background-color: #f5f5f5;
}

.welcome_card {
  padding: 10px 9px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome_card P {
  padding-top: 10px;
}

.showEmployeerow {
  /* background-color: #f5f5f5; */

  padding: 0px 8px 15px 8px;
}

.DashboardColXL9 {
  background-color: #ffffff;
  padding: 20px 10px;
  border-radius: 14px;
}

.Dashboard2FilterpartContainer {
  padding-top: 10px;
  background-color: white;
  border-radius: 14px;
}

.custom_dashboard_right_side_cards {
  border-radius: 10px;
  background-color: #fff;
  min-height: 192px;
}

.custom_dashboard_right_side_cards p {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
}

.dashP {
  padding: 10px 0px;
}

.dashP {
  padding: 15.5px 0px;
}

.employeeP {
  padding-top: 4px;
}

.invoiceP {
  padding-top: 4px;
}

.helpP {
  padding-top: 4px;
}

/* Dashboard 1 End */
/* Dashboard 2 Start */

.dashboard2 {
  margin: 0px 0px;
}

.MHEmployeeImageandText > span:nth-child(2) {
  font-size: 20px;
  font-weight: 700;
  color: #c6a34f;
}

.MHEmployeeImageandText img {
  margin-right: 8px;
}

.MHEmployeeImageandText > span:nth-child(3) {
  font-size: 18px;
  color: #111111;
}

.MHEmployeeImage > span:nth-child(1) {
  color: #c6a34f;
}

.MHEmployeeText > span:nth-child(1) {
  font-size: 20px;
  font-weight: 700;
  color: #c6a34f;
}

.MHEmployeeText > span:nth-child(2) {
  font-size: 18px;
  color: #111111;
}

.customCardTitle {
  font-size: 15px;
  margin-left: 5px;
}

.rating_logo img {
  margin-left: 3px;
}

.ratingSpans {
  padding-left: 4px;
  font-size: 13px !important;
  margin-top: 3.5px;
}

.ratingSpans > span:nth-child(1) {
  color: #111111 !important;
}

.ratingSpans span:nth-child(2) {
  color: #a6a6a6;
}

.experienceSpan > span:nth-child(1) {
  color: #a6a6a6 !important;
}

.experienceSpan > span:nth-child(2) {
  color: #111111 !important;
}

.totalHoursSpan > span:nth-child(1) {
  color: #7b7b7b !important;
}

.rate > span:nth-child(1) {
  color: #7b7b7b !important;
}

.experienceSpan {
  padding-left: 4px;
  margin-top: 3.5px;
  font-size: 13px;
}

.custom_card_padding {
  padding: 1rem 5px;
}

.Dashboard2-card-img-top {
  padding: 8px;
}

.bookNowButton {
  padding: 10px 30px;
  border: none;
  background-color: #c6a34f;
  color: white;
  -webkit-border-top-left-radius: 14px;
  -webkit-border-bottom-right-radius: 014px;
  -moz-border-radius-topleft: 14px;
  -moz-border-radius-bottomright: 014px;
  border-top-left-radius: 14px;
  border-bottom-right-radius: 014px;
}

.Dashboard2CardTItle {
  color: #111111 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.Dashboard2Card_rating {
  color: #111111 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.Dashboard2Card_count {
  color: #a6a6a6 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.Dashboard2ExpSpan {
  color: #a6a6a6 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.Dashboardcard2Years {
  color: #111111 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.dashboard2chefwrapper span {
  color: #111111 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.dashboard2totalhourspan {
  color: #a6a6a6 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.dashboard2totalhoursspent {
  color: #111111 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.Dashboard2Rate {
  color: #a6a6a6 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.Dashboard2Perhour {
  color: #111111 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.Dashboard2BookNowButton {
  margin-top: 14px;
}

.Dashboard2BookNowButton button {
  margin-left: 5px;
  padding: 0px 23px;
  height: 37px;
  border: none;
  background-color: #c6a34f;
  color: white;
  -webkit-border-top-left-radius: 14px;
  -webkit-border-bottom-right-radius: 14px;
  -moz-border-radius-topleft: 14px;
  -moz-border-radius-bottomright: 014px;
  border-top-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.DashboardEmployeeCard {
  height: fit-content;
  margin-right: 5px !important;
  margin-left: 5px !important;
  border-radius: 14px !important;
}

.Dashboard2CardssecondRow {
  margin-top: 20px;
}

/* Dashboard 2 end */

/* Filter Start */

.customforfilerrow2 {
  padding-top: 20px;
  border-radius: 14px;
  height: fit-content;
}

.btn-group img {
  margin-right: 10px;
}

.dropdown-toggle {
  padding-right: 50px;
}

.dropdown-toggle .glyphicon {
  margin-left: 20px;
  margin-right: -40px;
}

.dropdown-menu > li > a:hover {
  background: #f1f9fd;
}

/* $search-blue */
.dropdown-header {
  background: #ccc;
  font-size: 14px;
  font-weight: 700;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.firstFilterRow {
  margin-bottom: 20px;
}

.filtersTitle p {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  color: #7b7b7b;
}

.resetData {
  text-align: end;
}

.resetData button {
  margin-top: 7.5px;

  font-size: 13px;
  border: none;
  background: none;
  color: #ff5029;
  font-weight: 600;
}

.DashboardFilterCategoryDropdown {
  border: 1px solid #a6a6a6;
  height: 65px;
  /* background-color: #fafafa;
    color: #111111; */
  border-radius: 7.4px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.chefDropdown :hover {
}

.ratingRow {
  margin: 10px 0px;
}

.experienceH6 {
  color: #000000;
}

.DashboardFilterexperienceDropown {
  border: 1px solid #a6a6a6;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  height: 65px;
  background-color: #fafafa;
  color: #111111;
  border-radius: 7.4px;
}

.filterApply {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
  padding: 10px 30px;
  border: none;
  background-color: #c6a34f;
  color: white;
  -webkit-border-top-left-radius: 14px;
  -webkit-border-bottom-right-radius: 014px;
  -moz-border-radius-topleft: 14px;
  -moz-border-radius-bottomright: 014px;
  border-top-left-radius: 14px;
  border-bottom-right-radius: 014px;
}

.ratingRow {
  padding: 0px !important;
}

/* Filter End */

/* Pagination Start */
.pagination {
  padding: 13px 0px;
}

.paginationLiA li a {
  font-size: 17px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  border: none;
  border-radius: 9.58px;
  margin: 0px 7px;
  color: #111111;
  background-color: #f1f1f1;
}

.paginationLiA li :active {
  color: white;
  background-color: #c6a34f;
}

.paginationLiA li :hover {
  color: white;
  background-color: #c6a34f;
}

.paginationLiA li:first-child :hover {
}

/* Pagination End */

/* Footer Start */
.map_icon {
  left: 37%;
  position: absolute;
  bottom: -101%;
}

.Dashboardfooter {
  z-index: -1;
  padding: 100px 10px 80px 10px;
  background-color: #111111;
}

.DashboardFooterleftPartLinks ul {
  text-decoration: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.DashboardFooterleftPartLinks li {
  display: flex;
  flex-direction: column;
}

.DashboardFooterleftPartLinks li a {
  text-transform: uppercase;
  margin-bottom: 15px;
  text-decoration: none;
  color: white !important;
  display: block;
  padding: 5px 0;
}

.DashboardFootermiddlePart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DashboardFootermiddlePart h3 {
  margin-bottom: 33px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 17px;
  color: #fff;
}

.DashboardFootermiddlePart h4 {
  margin-bottom: 33px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #c6a34f;
}

.DashboardFootermiddlePart h5 {
  margin-bottom: 25px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #c6a34f;
}

.DashboardFootermiddlePart img {
}

.DashboardFootermiddlePart h6 {
  text-align: center;
  padding: 10px 103px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

.midDashboardFootermiddlePartlePart span {
  font-family: "poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #fff;
}

.DashboardFootersocialMediaIcons {
  margin: 20px 10px;
}

.DashboardFootersocialMediaIcons a {
  margin: 0px 10px;
  text-decoration: none;
}

.placeImgWrapper {
  display: inline;
}

.placeImgWrapper img {
  position: absolute;
  right: 62%;
  bottom: -470.2%;
}

.DashboardFooterrightPartLinks ul {
  text-align: right;
  text-decoration: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.DashboardFooterrightPartLinks li {
  display: flex;
  flex-direction: column;
}

.DashboardFooterrightPartLinks li a {
  margin-bottom: 15px;
  text-decoration: none;
  color: white !important;
  display: block;
  padding: 5px 0;
}

.rightPartLogoWrapper {
}

.rightPartLogoWrapper a {
  text-decoration: none;
}

.sliderImageWrapper {
}

.sliderNew {
  padding: 100px 0px;
}

.singleBox img {
  width: 85%;
}

.customFlex {
  flex-direction: row;
}

.DashboardfooterFinal {
  margin-top: 40px;
  padding: 15px 30px;
  background-color: #c6a34f;
  border-radius: 10px;
}

.DashboardfooterTextWrpper span {
  padding-top: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #fff;
}

.rating-bar input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background-color: #007bff;
  border-radius: 50%;
  cursor: pointer;
}

.rating-bar input[type="range"]::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background-color: #007bff;
  border-radius: 50%;
  cursor: pointer;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  height: 300px;
}
.imageContainer.job{
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  height: 220px;
}

.hoverOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  pointer-events: none;
}

.viewMoreText {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s ease;
}

.imageContainer:hover .hoverOverlay {
  opacity: 1;
}

.imageContainer:hover .viewMoreText {
  opacity: 1;
  transform: translateY(0);
}

.imageContainer:hover .Dashboard2-card-img-top {
  transform: scale(1.1);
  filter: blur(4px);
  transition: all 0.5s ease;
}

.Dashboard2-card-img-top {
  transition: all 0.5s ease;
}
.job-card-title{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
