/*font-family: 'Poppins', sans-serif;
font-family: 'Montserrat', sans-serif;
F6F1E5
*/
/* Custom Select Button */
.header_containercustom_select:focus {
    color: #7b7b7b !important;
    border-color: #c6a34f !important;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.25) !important;
}

.custom_select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    /* background-image: url("path/to/custom-arrow.png"); */
    background-repeat: no-repeat;
    background-position: right center;
    width: 100%;
    color: #7b7b7b;
    padding: 8px 40px;
    border: 1px solid #c6a34f;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 29.98px;
}

/* Custom Select Button */

.custom_coloring_row {
    background-color: #f5f5f5;
}

.Registration_left_part {
    z-index: 1;
    overflow: hidden;
    min-height: 100vh;
    background-color: #f6f1e5;
    padding-top: 70px;
}

.button_wrapper {
    margin-left: 50px;
}

.logo_wrapper {
    padding-top: 35px;
    padding-bottom: 35px;
}

.logo_wrapper img {
    width: 230.32px;
    /* height: 235.75px; */
}

.premier_stuffing_wrapper h1 {
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 60px;
}

.mh_premier_wrapping {
    line-height: 48px;
    padding-left: 30px;
    padding-right: 30px;
}

.mh_premier_wrapping p {
    padding-left: 65px;
    padding-right: 65px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.left_part_custom_row {
    position: relative;
}

.Registration_page_right_part {
    overflow: hidden;
    z-index: 0;
    background-color: #f5f5f5;
}

.eclips_wrapper1 img {
    width: 13%;
    z-index: 1;
    position: absolute;
    left: 43%;
    top: 0px;
}

.clientButtonWrapper {
    margin-top: 29px;
    height: 70px;
    /* border: 1px solid red; */
}

/* .ClientButtonNavItem:active {
    background-color: #c6a34f !important;
} */
.client_tab_button:hover {
    border: none !important;
    color: black;
}

.client_tab_button {
    width: 200px;
    border-radius: 6.17px;
    background-color: #ffffff !important;
    width: 180px;
    color: black;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 17px;
}

.client_tab_button.active {
    background-color: #c6a34f !important;
    color: white !important;
}

.employee_tab_button {
    border-radius: 6.17px;
    border: none !important;
    background-color: #ffffff !important;
    width: 200px;
    color: black;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 17px;
}

.employee_tab_button.active {
    background-color: #c6a34f !important;
    color: white !important;
}

.employee_tab_button:hover {
    border: none !important;
    color: black;
}

/* .nav-link {
    background-color: #fff !important;
    color: black !important;
} */

/* .nav-link:focus,
.nav-link:hover {
    color: black;
} */
.card_looking_container {
    padding: 30px 30px 10px 30px;
}

.formWrappper {
    background-color: #fff;
    border-radius: 10px;
}

.hidden_input {
    visibility: hidden;
}

.inputLogoWrapper {
    position: relative;
}

.inputLogoWrapper img {
    position: absolute;
    top: 14px;
    left: 14px;
}

.custom_client_input_for_registration_page {
    color: #7b7b7b;
    padding: 8px 40px;
    border: 1px solid #c6a34f;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 29.98px;
}

.resturauntNameWrapper {
    position: relative;
}

.restaurantNameText {
    position: absolute;
    top: -7px;
    left: 15px;
    padding: 0px 3px;
    background-color: #ffffff;
    color: #c6a34f;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 15.61px;
}

.custom_client_input_for_registration_page:focus {
    color: #7b7b7b !important;
    border-color: #c6a34f !important;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.25) !important;
}

.please_provide_wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: relative;
}

.pleaseProvideText {
    margin: 0px;
    padding: 12px 8px;
    background-color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #858585;
    position: absolute;
    z-index: 1;
    top: -23px;
}

.dropdownContainer {
    margin-top: 30px;
    padding: 0px;
}

.btn-custom-dropdown {
    width: 100%;
    border-radius: 8px;
    background-color: #fff !important;
    color: #7b7b7b !important;
    padding: 12px 40px !important;
    border: 1px solid #c6a34f !important;
}

.btn-custom-dropdown :focus {
    color: #7b7b7b !important;
    border-color: #c6a34f !important;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.25) !important;
}

.how_youKnow_text {
    color: #c6a34f;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 15.61px;
}

.register_button {
    margin-top: 20px;
    margin-bottom: 19px;
    width: 300px;
    height: 55px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 17px;
    border-radius: 12px;
    background-color: #c6a34f;
    color: white;
}

.employee_register_button {
    padding-top: 14px;
    margin-top: 10px;
    margin-bottom: 19px;
    width: 300px;
    height: 55px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 17px;
    border-radius: 12px;
    background-color: #c6a34f;
    color: white;
}

.accountandregisterwrapper {
    margin: 16px 0px;
}

.accountandregisterwrapper span {
    margin-right: 2.5px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.accountandregisterwrapper a {
    margin-left: 2.5px;
    color: #ddbd68;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.eclipse_wrapper2 img {
    z-index: 1;
    width: 6%;
    position: absolute;
    bottom: 0%;
    right: 0%;
}

.customImageThumbnail {
    border: 0px !important;
    padding: 20px 0px;
}

/* Test */
.logo-upload-btn {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.btn-file {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.btn-file:focus,
.logo-upload-btn:focus {
    outline: none;
}

.logo-upload-btn img {
    max-width: 100%;
    height: auto;
    display: block;
}

.cv_button {
    margin-top: 10px;
}