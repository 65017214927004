.about_us_section_title {
  font-size: 45px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  color: #222;
  position: relative;
  margin-bottom: 20px;
  line-height: 1.25em;
}

.about_us_section_sub_title {
  font-size: 15px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  color: #666;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 6px;
  margin-bottom: 5px;
}

.about_us_section_paragraph {
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.75em;
  color: #666;
  margin-bottom: 20px;
  text-align: justify;
}

.inputContainer {
  position: relative;
}

.inputIcon {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust the left distance based on your preference */
  transform: translateY(-50%);
}
