$color-white: #fff;
$brand-color-g: #348e80;
$brand-color: #df8625;

@mixin respond-to($breakpoint) {
    @if $breakpoint =="xs" {
        @media screen and (max-width: 767px) {
            @content;
        }
    }

    @else if $breakpoint =="sm" {
        @media (min-width: 768px) and (max-width: 991px) {
            @content;
        }
    }

    @else if $breakpoint =="md" {
        @media (min-width: 992px) and (max-width: 1199px) {
            @content;
        }
    }

    @else if $breakpoint =="lg" {
        @media screen and (min-width: 1200px) {
            @content;
        }
    }
}





.ps-timeline-sec {
    position: relative;
    background: $color-white;

    .container {
        position: relative;

        ol {

            @include respond-to(xs) {
                &:before {
                    background: #C6A34F;
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    position: absolute;
                    top: 130px !important;
                    left: 36px !important;
                }

                &:after {
                    background: #C6A34F;
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    position: absolute;
                    top: inherit !important;
                    left: 36px;
                }

                &.ps-timeline {
                    margin: 130px 0 !important;
                    border-left: 2px solid #C6A34F;
                    padding-left: 0 !important;
                    padding-top: 120px !important;
                    border-top: 0 !important;
                    margin-left: 25px !important;

                    li {
                        height: 220px;
                        float: none !important;
                        width: inherit !important;
                        margin-bottom: 20px;

                        .img-handler-bot {
                            margin-left: 10px !important;

                            img {
                                width: 100%;
                            }
                        }

                        .img-handler-top {
                            margin-left: 10px !important;

                            img {
                                width: 100%;
                            }
                        }

                        &:nth-child(4) {
                            // background-color: #df8625;
                            margin-top: 80px !important;
                        }

                        &:last-child {
                            margin: 0;
                            bottom: 0 !important;
                            // height: 120px;

                            .img-handler-bot {
                                bottom: 40px !important;
                                width: 40% !important;
                                margin-left: 20px !important;
                                margin-top: 0 !important;

                                img {
                                    width: 100%;
                                }
                            }

                            .ps-top {
                                margin-bottom: 0 !important;
                                top: 20px;
                                width: 50% !important;
                            }
                        }



                        span {
                            left: 0 !important;

                            &.ps-sp-top {
                                &:before {
                                    content: none !important;
                                }

                                &:after {
                                    content: none !important;
                                }
                            }

                            &.ps-sp-bot {
                                &:before {
                                    content: none !important;
                                }

                                &:after {
                                    content: none !important;
                                }
                            }
                        }

                        .img-handler-top {
                            position: absolute !important;
                            bottom: 150px !important;
                            width: 30% !important;
                            float: left !important;
                            margin-left: 20px !important;
                            margin-bottom: 0 !important;

                            img {
                                margin: 0 auto !important;
                                width: 80% !important;
                            }
                        }

                        .img-handler-bot {
                            position: absolute !important;
                            bottom: 115px !important;
                            width: 30% !important;
                            float: left !important;
                            margin-left: 20px !important;
                            margin-bottom: 0 !important;

                            img {}
                        }

                        p {
                            text-align: left !important;
                            width: 100% !important;
                            margin: 0 auto !important;
                            margin-top: 0px !important;
                        }

                        .ps-top {
                            width: 60% !important;
                            float: right !important;
                            right: 0;
                            top: -20px;
                        }

                        .ps-bot {
                            width: 60% !important;
                            float: right !important;
                            right: 0;
                            top: -50px;
                        }
                    }
                }
            }


            &:before {
                background: #C6A34F;
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 100%;
                position: absolute;
                left: 8px;
                top: 49.5%;
            }

            &:after {
                background: #C6A34F;
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 100%;
                position: absolute;
                right: 8px;
                top: 49.5%;
            }

            &.ps-timeline {
                margin: 220px 0;
                padding: 0;
                border-top: 2px solid #C6A34F;
                list-style: none;

                li {
                    float: left;
                    width: 20%;
                    padding-top: 30px;
                    position: relative;

                    span {
                        width: 50px;
                        height: 50px;
                        margin-left: -25px;
                        background: #C6A34F;
                        border: 4px solid #C6A34F;
                        border-radius: 50%;
                        box-shadow: 0 0 0 0px #C6A34F;
                        text-align: center;
                        line-height: 50px -10;
                        color: #C6A34F;
                        font-size: 2em;
                        font-style: normal;
                        position: absolute;
                        top: -26px;
                        left: 50%;

                        &.ps-sp-top {
                            &:before {
                                content: '';
                                color: #C6A34F;
                                width: 2px;
                                height: 50px;
                                background: #C6A34F;
                                position: absolute;
                                top: -50px;
                                left: 50%;
                            }

                            &:after {
                                content: '';
                                color: #C6A34F;
                                width: 8px;
                                height: 8px;
                                background: #C6A34F;
                                position: absolute;
                                bottom: 90px;
                                left: 44%;
                                border-radius: 100%;
                            }
                        }

                        &.ps-sp-bot {
                            &:before {
                                content: '';
                                color: #C6A34F;
                                width: 2px;
                                height: 50px;
                                background: #C6A34F;
                                position: absolute;
                                bottom: -50px;
                                left: 50%;
                            }

                            &:after {
                                content: '';
                                color: #C6A34F;
                                width: 8px;
                                height: 8px;
                                background: #C6A34F;
                                position: absolute;
                                top: 90px;
                                left: 44%;
                                border-radius: 100%;
                            }
                        }
                    }

                    .img-handler-top {
                        position: absolute;
                        bottom: 0;
                        margin-bottom: 130px;
                        width: 100%;

                        img {
                            display: table;
                            margin: 0 auto;
                        }
                    }

                    .img-handler-bot {
                        position: absolute;
                        margin-top: 60px;
                        width: 100%;

                        img {
                            display: table;
                            margin: 0 auto;
                        }
                    }

                    p {
                        text-align: center;
                        width: 80%;
                        margin: 0 auto;
                    }

                    .ps-top {
                        position: absolute;
                        bottom: 0;
                        margin-bottom: 100px;
                        // margin-left: 5px;
                    }

                    .ps-bot {
                        position: absolute;
                        margin-top: 35px;

                    }
                }
            }
        }
    }
}



@include respond-to(xs) {
    .right-top {
        display: none !important;
    }
}

// ... (Your existing SCSS code)

.email-container {
    border-radius: 15px;
    background-color: $color-white;
    margin: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    input {
      flex: 1; // Take up available space
      border-radius: 10px;
      background-color: $color-white;
      border: 1px solid $color-white;
      padding-left: 1.25rem; // equivalent to px-5 in Bootstrap
    }
  
    a {
      flex-shrink: 0; // Prevent shrinking
      background-color: #C6A34F;
      color: $color-white;
      border: 1px solid #C6A34F;
      border-radius: 15px;
      text-decoration: none;
      padding: 0.75rem 1rem; // equivalent to px-4 and py-3 in Bootstrap
    }
  
    @include respond-to(xs) {
      flex-direction: column;
      align-items: stretch;
  
      input {
        margin-bottom: 1rem;
      }
  
      a {
        margin-left: 0;
      }
    }
  }
  
  // ... (Your existing SCSS code)

.emailnumber {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  
    @include respond-to(xs) {
      input, a {
        margin-left: 0; // Reset the negative margin
        font-size: 14px; // Reduce font size for mobile
        word-wrap: break-word; // Break words if needed
        overflow-wrap: break-word; // Alternative to word-wrap
      }
    }
  }
  .ReactFlagsSelect-module_selectBtn__19wW7{
    border: none !important;
  }
  
  // ... (Your existing responsive styles)
  