/* MediaCarousel.css */
.media-container {
  position: relative;
  width: 100%;
}

.media-image {
  width: 100%;
  height: 400px;
  object-fit: cover; /* This ensures the image covers the entire container */
  border-radius: 20px !important;
}

.video-container {
  position: relative;
  width: 100%;
  height: 400px;
}

.title-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 18px;
  /* font-weight: bold; */
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  width: 70%;
  padding: 10px;
  border-radius: 50px;
}

/* Styles for mobile screens */
@media (max-width: 767px) {
  .media-image {
    height: 200px; /* Adjust the height for smaller screens */
    border-radius: 10px !important; /* Adjust the border-radius for smaller screens */
  }

  .video-container {
    height: 200px; /* Adjust the height for smaller screens */
  }

  .title-overlay {
    font-size: 14px; /* Adjust the font size for smaller screens */
    padding: 5px; /* Adjust the padding for smaller screens */
    width: 80%; /* Adjust the width for smaller screens */
    border-radius: 30px; /* Adjust the border-radius for smaller screens */
  }
}
