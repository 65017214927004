.arrow-button {
  background-color: #c6a34f;
  color: white;
  font-size: 30px;
  border-radius: 30%;
  margin-top: 19px;
}
.minus-margin {
  margin-left: -15px;
}
@media (min-width: 300px) {
  .custom-margin {
    margin-top: 13px;
  }
}
.calender-main-div {
  border: 1px solid rgba(128, 128, 128, 0.178);
  border-radius: 15px;
}
.list-main-div {
  border: 1px solid rgba(128, 128, 128, 0.178);
  border-radius: 15px;
}

@media screen and (min-width: 768px) {
  .calender-main-div {
    margin: 50px;
  }
}

@media screen and (max-width: 767px) {
  .calender-main-div {
    margin: 0;
  }
}
.tab-item {
  display: flex;
}
@media screen and (max-width: 992px) {
  .tab-item {
    display: block;
  }
}

.calender-main {
  border-radius: 15px;
}

@media screen and (min-width: 768px) {
  .calender-main {
    margin: 40px;
  }
}

@media screen and (max-width: 767px) {
  .calender-main {
    margin: 0;
  }
}
.calender {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: White;
  border-radius: 15px;
}
.custom-btn:focus {
  outline: none;
}
.event-title {
  font-size: 15px;
  margin-top: -5px;
  margin-bottom: -5px;
  background-color: #c6a34f;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .event-title {
    font-size: 8px;
    margin-top: -5px;
    margin-bottom: -5px;
    background-color: #c6a34f;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
  }
}
.select-border {
  border: 1px solid #c6a34f;
  color: #c6a34f;
  margin: 1px;
  max-width: 150px;
  height: 20px;
  font-size: 15px;
  outline: none;
}

@media (max-width: 820px) {
  .select-border {
    max-width: 60px;
  }
}

.common-border {
  border: 1px solid #c6a34f;
  border-radius: 15px;
}
.month {
  background-color: #c6a34f;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0;
  color: white;
}
.common-border2 {
  border: 1px solid rgba(128, 128, 128, 0.178);
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
  padding-top: 3px;
  margin-left: -8px;
  padding-right: 10px;
  padding-left: 5px;
  padding-bottom: 5px;
}
.common-color {
  color: #c6a34f;
}
.date-width {
  width: 120px;
}
.btn-show-more {
  background-color: none;
  font-size: 15px;
  border: none;
  outline: none;
  color: #c6a34f;
}
@media (max-width: 820px) {
  .btn-show-more {
    font-size: 10px;
  }
}

/* CalendarComponent.css */

.weekday-labels {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
  height: 56px;
  background-color: #c6a34f;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: white;
  .weekday-label {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
  }
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* Display days in a row */
  gap: 2px;
  padding-bottom: 10px;
  /* Gap between cells */
}

.calendar-day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  // width: 250px;
  min-width: 50px;
  border: 1px solid #ddd;
  padding: 0px 10px;

  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;
  position: relative;
  overflow: hidden;

  h6 {
    position: absolute;
    right: 49%;
    top: 10px;
    // font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }
  p {
    background-color: #c6a34f;
    color: white;
    margin: 0 0 5px 0;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
    display: -webkit-box;
    // min-width: 100px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.calendar-day-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  // width: 250px;
  min-width: 50px;
  border: 1px solid #ddd;
  padding: 0px 10px;

  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;
  position: relative;
  overflow: hidden;

  h6 {
    position: absolute;
    right: 49%;
    top: 10px;
    // font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }
  p {
    background-color: #c6a34f;
    color: white;
    margin: 0 0 5px 0;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
    display: -webkit-box;
    // min-width: 100px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.calendar-day.weekend {
  background-color: rgb(199, 199, 199);
  /* Light gray background for disabled dates */
  color: #ccc;
  /* Dim text color for disabled dates */
  cursor: not-allowed;
  /* Show 'not-allowed' cursor */
  pointer-events: none;
  /* Disable click events */
}

/* Styles for screens with a max-width of 768px */
@media (max-width: 768px) {
  .weekday-labels,
  .calendar-grid {
    font-size: 14px;
  }

  .calendar-day {
    height: 100px;
    // width: 200px;
    p {
      margin: 0 0 1px 0;
      padding: 5px 5px;
      font-size: 8px;
      font-weight: 500;
      line-height: 5px;
    }
  }

  .calendar-day h6 {
    font-size: 14px;
  }

  .calendar-day p {
    font-size: 12px;
  }
}
