.weekday-labels {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
  background-color: #c6a24f;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: white;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* Display days in a row */
  gap: 2px;
  /* Gap between cells */
  margin-top: 10px;
}

.calendar-day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  border: 1px solid #ddd;
  padding: 0px 10.7px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;
  position: relative;

  h6 {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 18px;
  }
}

.calendar-day.weekend {
  background-color: rgb(199, 199, 199);
  /* Light gray background for disabled dates */
  color: #ccc;
  /* Dim text color for disabled dates */
  cursor: not-allowed;
  /* Show 'not-allowed' cursor */
  pointer-events: none;
  /* Disable click events */
}

@media print {
  @page {
    size: 1200px; // Set the page size to 1200px
    margin: 0; // Remove default margins
  }
}
