.chat {
  ul {
    li {
      list-style: none;
    }
  }
  float: left;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  color: #434651;

  .chat-history {
    // padding: 30px 30px 20px;
    padding-left: 0;
    border-bottom: 2px solid white;
    overflow-y: auto;
    height: calc(100vh - 270px);

    .message-data {
      margin-bottom: 5px;
    }

    .message-data-time {
      color: lighten(gray, 8%);
      padding-left: 6px;
    }

    .message {
      color: white;
      padding: 9px 10px;
      font-size: 16px;
      border-radius: 7px;
      margin-bottom: 20px;
      width: 70%;
      position: relative;
    }

    .my-message {
      background: #737373;
    }

    .other-message {
      background: #c6a24f;
      text-align: end;
      &:after {
        border-bottom-color: #c6a24f;
        left: 93%;
      }
    }
  }

  .chat-history::-webkit-scrollbar {
    display: none;
  }

  .chat-message {
    textarea {
      width: 100%;
      padding: 10px 20px;
      font: 14px/22px "Lato", Arial, sans-serif;
      margin-bottom: 1px;
      border-radius: 5px;
      resize: none;
    }

    .fa-file-o,
    .fa-file-image-o {
      font-size: 16px;
      color: gray;
      cursor: pointer;
    }

    button {
      // float: right;
      color: #c6a24f;
      font-size: 16px;
      text-transform: uppercase;
      border: none;
      cursor: pointer;
      font-weight: bold;
      background: #f2f5f8;

      &:hover {
        color: darken(#c6a24f, 7%);
      }
    }
  }

  .me {
    color: #c6a24f;
  }

  .align-left {
    text-align: left;
  }

  .align-right {
    text-align: right;
  }

  .float-right {
    float: right;
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
